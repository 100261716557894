import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header.component';
import {MenuModule} from 'primeng/menu';
import {MenuItem} from 'primeng/api';
import {SidebarModule} from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularOtpLibModule } from 'angular-otp-box';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';

const imports = [
  CommonModule,
  MenuModule,
  SidebarModule,
  DialogModule,
  FormsModule,
  ReactiveFormsModule,
  AngularOtpLibModule,
  ButtonModule,
  InputTextModule,
  ToastModule
  
 
];

const components = [
    HeaderComponent
];

const routes: Routes = [
  {
    path: 'sawai-header',
    component: HeaderComponent
  }
];

@NgModule({
  declarations: [components],
  imports: [
    imports,
    RouterModule.forChild(routes),

  ],
  exports: [
    RouterModule,
    components
  ]
})
export class HeaderModule { }
