import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { DEPLOY_URL } from './deploy-url';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const deployUrl = (function () {

  if (window.location.hostname.endsWith('chetak.com')) {
    return 'https://cdn.bajajauto.com' + window.location.pathname;
  } else {
    return window.location.origin + window.location.pathname;
  }
})();

const DEPLOY_URL_PROVIDER = {
  provide: DEPLOY_URL,
  useValue: deployUrl,
};

platformBrowserDynamic([DEPLOY_URL_PROVIDER])
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));
