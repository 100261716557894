import { ChangeDetectorRef, Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize, delay } from "rxjs/operators";
import { AppService } from "src/app/app.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //   alert('herer')
    // if (!req.url.includes("albums")) {
    //   return next.handle(req);
    // }
    // console.warn("LoaderInterceptor");

    const appService = this.injector.get(AppService);

    setTimeout(() => {
      // appService.showLoader = true;
      
      appService.show();
    })
    
    
    
   

    return next.handle(req).pipe(
      delay(1500),
      finalize(() => {
       
          // appService.showLoader = false;
          appService.hide();
          
          
      })
    );
  }
}
