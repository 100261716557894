<div *ngIf="showHeader" style="position: fixed;
right: 0;
color: #fff;
background-color: #000;
z-index: 99999;

bottom: 2rem;
padding: 0.5rem;
padding-right: 2.35rem;
width: 6rem;
font-size: 0.75rem;
cursor: pointer;" (click)="navigateByUrl()">
    BOOK NOW
</div>

<div *ngIf="showHeader" style="position: fixed;
left: 0;
z-index: 99999;

bottom: 0rem;
padding: 0.5rem;
padding-right: 2.35rem;
width: 6rem;
font-size: 0.75rem;
cursor: pointer;">
    <a class="whatapp-web" href="https://web.whatsapp.com/send?text=Hi&phone=+919823333502" data-text="Take a look at this awesome website:" target="blank">
        <img src="assets/whatsapp-48.png" width="50">
    </a>
    <a class="whatapp-mobile" href="whatsapp://send?text=Hi&phone=+919823333502" data-text="Take a look at this awesome website:">
        <img src="assets/whatsapp-48.png" width="50">
    </a>
</div>

<!-- bottom: 6.5rem; -->
<!-- <div style="position: fixed;
right: 0;
color: #fff;
background-color: #000;
z-index: 99999;
bottom: 2rem;
padding: 0.5rem;
padding-right: 2.35rem;
width: 6rem;
font-size: 0.75rem;
cursor: pointer;">
    ORDER FOOD ONLINE
</div> -->
<app-header *ngIf="showHeader"></app-header>

<router-outlet></router-outlet>

<div *ngIf="appService.showLoader" style="position: fixed;
top: 0;
z-index: 1200000;
height: 100vh;
width: 100%;
overflow: hidden;
background-color: #000000e6;">
    <div style="height: 100%;" class="p-grid p-align-center p-justify-center">
        <img src="assets/loader.gif" width="250px">
        <!-- <p-progressSpinner [style]="{width: '70px', height: '70px'}" styleClass="custom-spinner" strokeWidth="3" animationDuration=".5s"></p-progressSpinner> -->
    </div>
</div>

<div class="status-dialog">
    <p-dialog [(visible)]="displayRestaurantStatus" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <p-header>


        </p-header>



        <div class="p-col-12 p-grid p-align-center p-justify-center">
            <div style="font-size: 1.5rem;
            font-weight: 600;    text-align: center;">Website for Sawai Luxurious Home Stay is under construction. For booking Call us on 9823 3335 02.</div>
        </div>

        <p-footer>


        </p-footer>

    </p-dialog>
</div>
<!-- <p-toast></p-toast> -->