import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SawaiResortService {

    constructor(private _http: HttpClient) { }

    getBannerByType(type): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'banner/view_banner_type/'+type, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    getActivities(): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'activity/view_activity/all', httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    getCustomerFeedback(): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'testimonial/view_testimonial/all', httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    getNearByAttractions(): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'nearby/view_nearby/all', httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    getGalleryImages(type): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'banner/view_banner_type/'+ type, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    registerCustomer(reqData): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'user/insert_user', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    verifyCustomer(reqData): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'user/verify_user_otp', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    resendOTP(reqData): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'user/resend_user_otp', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }
    
    getOTP(reqData): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'user/send_user_otp', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    checkBookingAvailability(reqData): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'booking/booking_availibity', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    
    getBookingCalculation(reqData): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'booking/booking_calculation', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }
    

    addBooking(reqData): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'booking/insert_booking', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    getUserProfile(reqData): any{
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'user/profile_user/'+reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    updateUserProfile(reqData): any{
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'user/update_user',reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    updateBooking(reqData): any{
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'booking/update_payment', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    removeBooking(reqData): any{
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'booking/booking_remove', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }


    addMagicMoment(reqData): any{
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'movements/insert_movements', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    deleteMagicMoment(reqData): any{
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'movements/delete_movements', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }


    getLookingForPropertyList(): any{
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'property/view_property/all', httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    getBookingHistory(reqData): any{
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'user/view_booking_history/'+reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }
    

    getStayBenefits(): any{
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'benefit/view_benefit/all', httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }
    
    submitContactUS(reqData): any{
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'contactus/insert_contactus', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    addMovement(reqData): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'movements/insert_movements', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    validateCoupon(reqData): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'bookingcoupons/check_coupon', reqData, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    getActivePackages(): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.post(environment.api.defaultBase+'packages/view_packages/active',{}, httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }


    getVillaList(): any {

       
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return new Promise((resolve, reject) => {
            this._http.get(environment.api.defaultBase+'resorts/view_resorts/all', httpOptions)
                .subscribe((res: any) => {
                    if (res){
                        resolve(res);
                    }
                })
            });
    }

    

    



}
