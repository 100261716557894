
export const environment = {
  production: false,
  
  api: {
    // defaultBase:'https://sawaiveg.com/resorts/apiv2/',
    // imageBase: 'https://sawaiveg.com/resorts/apiv2/uploads/',

    // defaultBase:'http://localhost/Sawai-API/resorts/apiv1/',
    // imageBase:'http://localhost/Sawai-API/resorts/apiv1/uploads/'

    // defaultBase:'https://sawaimain.magicalstack.com/resorts/apiv2/',
    // imageBase:'https://sawaimain.magicalstack.com/resorts/apiv2/uploads/'

    // defaultBase:'https://sawai.magicalstack.com/resorts/apiv4/',
    // imageBase:'https://sawai.magicalstack.com/resorts/apiv4/uploads/',
    
    // defaultBase:'http://35.154.9.8/resorts/apiv4/',
    // imageBase:'http://35.154.9.8/resorts/uploads/'

    defaultBase:'https://www.sawaiveg.com/resorts/apiv2/',
    imageBase: 'https://www.sawaiveg.com/resorts/uploads/',
  },                 

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
