import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  
 navLinks: any;
 selectedItem : string = 'vegLinks';
 showHeader: boolean = false;

 displayRestaurantStatus: boolean = false;

  constructor(public router: Router, public appService: AppService) {
   

  }

  ngOnInit(){
    const me = this;

    // console.log(me.router.url.includes('/business-opportunities'))

    this.router.events.subscribe((res) => { 
      if(me.router.url.includes('/sawai-veg')){
        me.selectedItem = 'vegLinks';
      }else if(me.router.url.includes('/sawai-resort')){
        me.selectedItem = 'resortLinks'
      }

      if(me.router.url.includes('/admin')){
        me.showHeader = false
      }else{
        me.showHeader = true
      }
  })

    
    me.navLinks = {
      vegLinks: [
        {
          link: 'sawai-veg/home',
          label: 'Home'
        },
        {
          link: 'sawai-veg/about-franchise',
          label: 'About Franchise'
        },
        {
          link: 'sawai-veg/order-food-online',
          label: 'Order Food Online'
        },
        {
          link: 'sawai-veg/contact-us',
          label: 'Contact Us'
        }
      ],
      resortLinks: [
        {
        link: 'sawai-resort/home',
        label: 'HOME'
      },
      {
        link: 'sawai-resort/villas-and-rates',
        label: 'VILLAS & RATES'
      },
      {
        link: 'sawai-resort/reservation',
        label: 'RESERVATION'
      },
      
      {
        link: 'sawai-resort/activities',
        label: 'ACTIVITIES'
      },
      {
        link: 'sawai-resort/about-us',
        label: 'ABOUT US'
      },
      {
        link: 'sawai-resort/gallery',
        label: 'GALLERY'
      },
      
      {
        link: 'sawai-resort/business-opportunities',
        label: 'BUSINESS OPPORTUNITIES'
      },
      
    ]
    }
  }

  navigateByUrl(){
    // this.displayRestaurantStatus = true;
    this.router.navigateByUrl('sawai-resort/reservation')
  }

}
