import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: "root"
})
export class AppService {

  showLoader:boolean = false;

  userLoggedIn: boolean = false;
  
  vegUserLoggedIn: boolean = false;

  constructor() {}

  show() {
    this.showLoader = true;
  }

  hide() {
    this.showLoader = false;
  }

LoggedIn(){
    this.userLoggedIn = true;
}

LoggedOut(){
    this.userLoggedIn = false;
}

VegLoggedIn(){
  this.vegUserLoggedIn = true;
}

VegLoggedOut(){
  this.vegUserLoggedIn = false;
}

showMessage(data){
 
  // this.messageService.add(data)
}


}
