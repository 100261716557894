import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ConfirmationService, MenuItem, MessageService, PrimeNGConfig } from "primeng/api";
import { AppService } from "src/app/app.service";
import { SawaiResortService } from "src/app/pages/sawai-resort/sawai-resort.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, ConfirmationService]
})

export class HeaderComponent implements OnInit {

  navLinks: any;
  selectedItem: string = 'vegLinks';
  userLoggedIn: Boolean;
  items: MenuItem[];
  display: boolean = false;
  displayRestaurantStatus: boolean = false;


  loginForm: FormGroup;
  registerForm: FormGroup;

  otpValue: any;
  action: any;

  displayModal: boolean;

  public settings = {
    length: 4,
    numbersOnly: true,
    timer: 1
  }

  vegUserLoggedIn: boolean;

  constructor(public router: Router, public appService: AppService, private fb: FormBuilder, private resortService: SawaiResortService, private cdr: ChangeDetectorRef, private messageService: MessageService, private primengConfig: PrimeNGConfig, private confirmationService: ConfirmationService) {


  }

  ngOnInit() {
    const me = this;


    this.loginForm = this.fb.group({

      'name': new FormControl('', Validators.required),

      'mobile': new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),



    });

    this.registerForm = this.fb.group({

      'fname': new FormControl('', Validators.required),
      'lname': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"), Validators.required]),

      'mobile': new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),

      // 'country': new FormControl('', Validators.required),
      // 'companyname': new FormControl(''),
      // 'address': new FormControl('', Validators.required),
      // // 'landmark': new FormControl('', Validators.required),
      // 'state': new FormControl('', Validators.required),
      // 'city': new FormControl('', Validators.required),
      // 'identity': new FormControl('', Validators.required),



    });

    // console.log(me.router.url.includes('/business-opportunities'))

    this.router.events.subscribe((res) => {
      if (me.router.url.includes('/sawai-veg')) {
        me.selectedItem = 'vegLinks';
      } else if (me.router.url.includes('/sawai-resort')) {
        me.selectedItem = 'resortLinks'
      }

      if(me.selectedItem == 'resortLinks'){

      if (localStorage.getItem('isValidUser') && localStorage.getItem('isValidUser') == 'true') {
        me.userLoggedIn = true;

        this.items = [
          // {label: '9823 3335 02',icon: 'pi pi-fw pi-mobile'},
          // {label: 'sawaiveg@gmail.com',icon: 'pi pi-fw pi-envelope'},

          { label: 'Profile', icon: 'pi pi-fw pi-user', routerLink: 'sawai-resort/user-profile' },

        ];

      } else {
        me.userLoggedIn = false;

        this.items = [
          // {label: '9823 3335 02',icon: 'pi pi-fw pi-mobile'},
          // {label: 'sawaiveg@gmail.com', icon: 'pi pi-fw pi-envelope'},
          { label: 'Login', icon: 'pi pi-fw pi-user', routerLink: 'sawai-resort/login' },
          { label: 'Register', icon: 'pi pi-fw pi-user-plus', routerLink: 'sawai-resort/register' }

        ];
      }
    }

    if(me.selectedItem == 'vegLinks'){

      if (localStorage.getItem('isValidVegUser') && localStorage.getItem('isValidVegUser') == 'true') {
        me.vegUserLoggedIn = true;

        this.items = [
          // {label: '9823 3335 02',icon: 'pi pi-fw pi-mobile'},
          // {label: 'sawaiveg@gmail.com',icon: 'pi pi-fw pi-envelope'},

          { label: 'Profile', icon: 'pi pi-fw pi-user', routerLink: 'sawai-veg/profile' },

        ];

      } else {
        me.vegUserLoggedIn = false;

        this.items = [
          // {label: '9823 3335 02',icon: 'pi pi-fw pi-mobile'},
          // {label: 'sawaiveg@gmail.com', icon: 'pi pi-fw pi-envelope'},
          { label: 'Login', icon: 'pi pi-fw pi-user', command: () => me.openModal('Login') },
        { label: 'Register', icon: 'pi pi-fw pi-user-plus', command: () => me.openModal('Register') }

        ];
      }
    }


      if(localStorage.getItem('isValidVegUser') && localStorage.getItem('isValidVegUser') == 'true'){
        this.vegUserLoggedIn = true;
       }else{
        this.vegUserLoggedIn = false;
       }


    })

    //  if(localStorage.getItem('isValidUser') && localStorage.getItem('isValidUser') == 'true'){
    //   me.userLoggedIn = true;
    //  }else{
    //   me.userLoggedIn = false;
    //  }


    me.navLinks = {
      vegLinks: [
        {
          link: 'sawai-veg/home',
          label: 'Home'
        },
        {
          link: 'sawai-veg/about-franchise',
          label: 'About Franchise'
        },
        {
          link: 'sawai-veg/order-food-online',
          label: 'Order Food Online'
        },
        {
          link: 'sawai-veg/contact-us',
          label: 'Contact Us'
        }
      ],
      resortLinks: [
        {
          link: 'sawai-resort/home',
          label: 'HOME'
        },
        {
          link: 'sawai-resort/villas-and-rates',
          label: 'VILLAS & RATES'
        },
        {
          link: 'sawai-resort/reservation',
          label: 'RESERVATION'
        },

        {
          link: 'sawai-resort/activities',
          label: 'ACTIVITIES'
        },
        {
          link: 'sawai-resort/about-us',
          label: 'ABOUT US'
        },
        {
          link: 'sawai-resort/gallery',
          label: 'GALLERY'
        },

        {
          link: 'sawai-resort/business-opportunities',
          label: 'BUSINESS OPPORTUNITIES'
        },

        {
          link: 'sawai-resort/contact-us',
          label: 'CONTACT US'
        },

      ]
    }
  }

  getLocalStorage() {

    const me = this;

    if(me.selectedItem == 'resortLinks'){
    if (localStorage.getItem('isValidUser') && localStorage.getItem('isValidUser') == 'true') {
      me.userLoggedIn = true;

      this.items = [
        { label: '9823 3335 02', icon: 'pi pi-fw pi-mobile' },
        { label: 'sawaiveg@gmail.com', icon: 'pi pi-fw pi-envelope' },

        { label: 'Profile', icon: 'pi pi-fw pi-user', routerLink: 'sawai-resort/user-profile' },

      ];

    } else {
      me.userLoggedIn = false;

      this.items = [
        { label: '9823 3335 02', icon: 'pi pi-fw pi-mobile' },
        { label: 'sawaiveg@gmail.com', icon: 'pi pi-fw pi-envelope' },
        { label: 'Login', icon: 'pi pi-fw pi-user', routerLink: 'sawai-resort/login' },
        { label: 'Register', icon: 'pi pi-fw pi-user-plus', routerLink: 'sawai-resort/register' }

      ];
    }
  }

  if(me.selectedItem == 'vegLinks'){
    if (localStorage.getItem('isValidVegUser') && localStorage.getItem('isValidVegUser') == 'true') {
      me.vegUserLoggedIn = true;

      this.items = [
        { label: '9823 3335 02', icon: 'pi pi-fw pi-mobile' },
        { label: 'sawaiveg@gmail.com', icon: 'pi pi-fw pi-envelope' },

        { label: 'Profile', icon: 'pi pi-fw pi-user', routerLink: 'sawai-veg/profile' },

      ];

    } else {
      me.vegUserLoggedIn = false;

      this.items = [
        { label: '9823 3335 02', icon: 'pi pi-fw pi-mobile' },
        { label: 'sawaiveg@gmail.com', icon: 'pi pi-fw pi-envelope' },
        { label: 'Login', icon: 'pi pi-fw pi-user', command: () => me.openModal('Login') },
        { label: 'Register', icon: 'pi pi-fw pi-user-plus', command: () => me.openModal('Register') }

      ];
    }
  }

  }

  openModal(action) {
    this.displayModal = true;
    this.action = action;
    this.loginForm.reset();
    this.registerForm.reset();
  }

  public onInputChange(e) {

    if (e.length == this.settings.length) {
      // e will emit values entered as otp and,
      // console.log('otp is', e);
      this.otpValue = e;
    } else if (e == -1) {
      // if e == -1, timer has stopped
      // console.log(e, 'resend button enables');
    } else if (e == -2) {
      // e == -2, button click handle
      // console.log('resend otp');
    }
  }

  getOtp(data) {

    const me = this;

    const reqBody = {
      user_ContactNo: data.mobile
    };



    me.resortService.getOTP(reqBody).then(
      (response) => {
        if (response.message_code == 1000) {
          this.messageService.add({ severity: 'success', summary: 'Success Message', detail: 'OTP sent successfully' });
          this.cdr.detectChanges();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error Message', detail: response.message_text });
          this.cdr.detectChanges();
        }
      },
      error => console.log(error));

  }


  resendOtp(data) {

    const me = this;

    const reqBody = {
      user_ContactNo: data.mobile
    };



    me.resortService.resendOTP(reqBody).then(
      (response) => {
        if (response.message_code == 1000) {
          this.messageService.add({ severity: 'success', summary: 'Success Message', detail: 'OTP resent successfully' });
          this.cdr.detectChanges();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error Message', detail: response.message_text });
          this.cdr.detectChanges();
        }
      },
      error => console.log(error));

  }

  login(data) {
    const me = this;

    const reqBody = {
      // Customer_ContactNo:data.mobile,
      // Customer_OTP: this.otpValue
      user_ContactNo: data.mobile,
      user_OTP: this.otpValue
    };



    me.resortService.verifyCustomer(reqBody).then(
      (response) => {
        console.log(response);
        if (response.message_code == 1000) {
          localStorage.setItem('vegUserMobile', data.mobile);
          localStorage.setItem('isValidVegUser', 'true');
          me.appService.VegLoggedIn();
          me.loginForm.reset();
          me.otpValue = null;

          this.messageService.add({ severity: 'success', summary: 'Success Message', detail: 'Logged in successfully' });
          this.cdr.detectChanges();

          this.displayModal = false;
          window.location.reload();

        } else {

          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid data' });
          this.cdr.detectChanges();

        }
      },
      error => console.log(error));

  }

  registerUser(data) {
    const me = this;



    const reqBody = {

      user_FirstName: data.fname,
      user_LastName: data.lname,
      user_ContactNo: data.mobile,
      user_Email: data.email,

    };



    me.resortService.registerCustomer(reqBody).then(
      (response) => {

        if (response.message_code == 1000) {
          me.registerForm.reset();
          me.action = 'Login';

          this.messageService.add({ severity: 'success', summary: 'Success Message', detail: 'Registered successfully' });
          this.cdr.detectChanges();


        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message_text });
          this.cdr.detectChanges();
        }
      },
      error => console.log(error));

  }


}
