import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';




const routes: Routes = [
  {
    path: '',
    redirectTo: '/sawai-veg/home',
    pathMatch: 'full'
  },
  {
    path: 'sawai-veg',
    loadChildren: () => import('./sawai-veg/sawai-veg.module').then(m => m.SawaiVegModule)
  },
  {
    path: 'sawai-resort',
    loadChildren: () => import('./sawai-resort/sawai-resort.module').then(m => m.SawaiResortModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  }

];


const modules = [
  
  
];


@NgModule({
  declarations: [],
  imports: [
    modules,
    RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' }),
  ],
  exports: [
    RouterModule
  ]
})
export class PagesModule { }
